import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Layout from './components/Layout';
import Introduction from './pages/Introduction';
import QuickStart from './pages/QuickStart';
import BestPractices from './pages/BestPractices';
import Features from './pages/Features';
import Integration from './pages/Integration';
import CommonIssues from './pages/CommonIssues';
import Tutorials from './pages/Tutorials';
import ReferenceUseCases from './pages/ReferenceUseCases';

const App = () => {
  return (
    <Router>
      <div className="pt-8"> {/* Add padding to account for the banner */}
        <div className="fixed top-0 left-0 right-0 z-[60] bg-light-surface dark:bg-dark-surface text-center py-1 border-b border-light-border dark:border-dark-border">
          <p className="text-sm text-gray-600 dark:text-gray-400">
            Built with ❤️ by <span className="text-[#FF3D00]">Ana</span>
          </p>
        </div>
        <Layout>
          <Routes>
            <Route path="/" element={<Introduction />} />
            <Route path="/quickstart" element={<QuickStart />} />
            <Route path="/best-practices" element={<BestPractices />} />
            <Route path="/features" element={<Features />} />
            <Route path="/integration" element={<Integration />} />
            <Route path="/common-issues" element={<CommonIssues />} />
            <Route path="/tutorials" element={<Tutorials />} />
            <Route path="/reference-use-cases" element={<ReferenceUseCases />} />
            <Route path="*" element={<Introduction />} />
          </Routes>
        </Layout>
      </div>
    </Router>
  );
};

export default App;

import React, { useEffect, useRef, useState } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import type { HTMLMotionProps } from 'framer-motion';

interface ScrollHeaderProps {
  title: string;
}

const ScrollHeader: React.FC<ScrollHeaderProps> = ({ title }) => {
  const [isVisible, setIsVisible] = useState(true);
  const headerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsVisible(entry.isIntersecting);
      },
      {
        threshold: 0,
        rootMargin: '-108px 0px 0px 0px' // Adjusted for banner (28px) + header height (80px)
      }
    );

    if (headerRef.current) {
      observer.observe(headerRef.current);
    }

    return () => {
      if (headerRef.current) {
        observer.unobserve(headerRef.current);
      }
    };
  }, []);

  const motionProps: HTMLMotionProps<"div"> = {
    initial: { opacity: 0, y: -20 },
    animate: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: -20 },
    transition: { duration: 0.2 }
  };

  return (
    <>
      <div ref={headerRef}>
        <h1 className="text-5xl font-bold mb-6 text-gray-900 dark:text-white">
          {title}
        </h1>
      </div>

      <AnimatePresence>
        {!isVisible && (
          <>
            {/* Mobile sticky header */}
            <div className="fixed left-0 right-0 md:hidden bg-light-surface dark:bg-dark-surface border-b border-light-border dark:border-dark-border" style={{ top: '92px', zIndex: 19 }}>
              <div className="max-w-7xl mx-auto px-6">
                <motion.div
                  {...motionProps}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    paddingTop: '0.75rem',
                    paddingBottom: '0.75rem'
                  }}
                >
                  <h2 className="text-xl font-bold text-gray-900 dark:text-white">
                    {title}
                  </h2>
                </motion.div>
              </div>
            </div>

            {/* Desktop floating header in navbar */}
            <div 
              className="fixed left-0 right-0 h-16 hidden md:block pointer-events-none" 
              style={{ top: '28px', zIndex: 21 }}
            >
              <div className="absolute inset-0 flex items-center justify-center">
                <motion.div
                  {...motionProps}
                >
                  <div className="text-2xl font-bold text-gray-900 dark:text-white">
                    {title}
                  </div>
                </motion.div>
              </div>
            </div>
          </>
        )}
      </AnimatePresence>
    </>
  );
};

export default ScrollHeader;

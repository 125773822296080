import React from 'react';
import { motion } from 'framer-motion';
import { FiCode, FiCheckCircle, FiAlertCircle } from 'react-icons/fi';
import ScrollHeader from '../components/ScrollHeader';

const QuickStart = () => {
  const steps = [
    {
      title: "Installation Options",
      description: "Ana is available in two formats:",
      options: [
        {
          title: "IDE Plugin",
          description: "Install Ana directly in your development environment:",
          steps: [
            "Open VS Code or your preferred IDE",
            "Go to Extensions/Plugins marketplace",
            "Search for 'Ana AI Engineer'",
            "Click Install and follow setup instructions"
          ]
        },
        {
          title: "Web Application",
          description: "Access Ana through your web browser:",
          steps: [
            "Visit app.openana.ai",
            "Sign up or log in to your account",
            "Start collaborating with Ana immediately"
          ]
        }
      ]
    },
    {
      title: "Getting Started",
      description: "Follow these steps to start working with Ana:",
      steps: [
        "Choose a task or issue you want to work on",
        "Structure your request using the What/How/Where/Outcome format",
        "Review Ana's suggestions and implement the solutions",
        "Provide feedback to help Ana learn your preferences"
      ]
    }
  ];

  const bestPractices = [
    {
      icon: <FiCode />,
      title: "Structure Your Tasks",
      description: "Use the What/How/Where/Outcome format for best results",
      example: {
        what: "Review code for security vulnerabilities",
        how: "Analyze the authentication module",
        where: "src/auth/login.ts",
        outcome: "Identify and fix potential security issues"
      }
    },
    {
      icon: <FiCheckCircle />,
      title: "Verify Results",
      description: "Always review Ana's suggestions before implementation",
      points: [
        "Test proposed changes in development",
        "Verify security implications",
        "Check for code style consistency"
      ]
    },
    {
      icon: <FiAlertCircle />,
      title: "Provide Context",
      description: "Help Ana understand your project better",
      points: [
        "Share relevant documentation",
        "Explain project-specific requirements",
        "Specify any constraints or preferences"
      ]
    }
  ];

  const cardClasses = `
    bg-light-card dark:bg-dark-card
    backdrop-blur-sm
    rounded-lg
    border border-light-border dark:border-dark-border
    p-6
    transition-all duration-200
    hover:border-primary-500/30 dark:hover:border-primary-500/30
    hover:shadow-lg hover:shadow-primary-500/5 dark:hover:shadow-primary-500/10
    mb-6
  `;

  const innerCardClasses = `
    bg-light-hover dark:bg-dark-hover
    backdrop-blur-sm
    rounded-lg p-6
    border border-light-border dark:border-dark-border
  `;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <ScrollHeader title="Quick Start Guide" />
        <p className="text-xl text-gray-600 dark:text-gray-400 mb-8">
          Get started with Ana in minutes. Choose your preferred way to access Ana and begin collaborating with your AI engineering teammate.
        </p>

        {/* Installation Steps */}
        {steps.map((section, index) => (
          <div key={index} className={cardClasses}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
                {section.title}
              </h2>
              <p className="text-gray-600 dark:text-gray-400 mb-6">
                {section.description}
              </p>

              {section.options ? (
                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  {section.options.map((option, idx) => (
                    <div key={idx} className={innerCardClasses}>
                      <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">
                        {option.title}
                      </h3>
                      <p className="text-gray-600 dark:text-gray-400 mb-4">
                        {option.description}
                      </p>
                      <ol className="list-decimal list-inside space-y-2 text-gray-600 dark:text-gray-400">
                        {option.steps.map((step, stepIdx) => (
                          <li key={stepIdx}>{step}</li>
                        ))}
                      </ol>
                    </div>
                  ))}
                </div>
              ) : (
                <ol className="list-decimal list-inside space-y-2 text-gray-600 dark:text-gray-400">
                  {section.steps.map((step, stepIdx) => (
                    <li key={stepIdx}>{step}</li>
                  ))}
                </ol>
              )}
            </motion.div>
          </div>
        ))}

        {/* Best Practices */}
        <div className={cardClasses}>
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <h2 className="text-2xl font-bold mb-6 text-gray-900 dark:text-white">
              Best Practices
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              {bestPractices.map((practice, index) => (
                <div
                  key={index}
                  className={innerCardClasses}
                >
                  <div className="text-xl text-primary-500 dark:text-primary-400 mb-3">
                    {practice.icon}
                  </div>
                  <h3 className="font-semibold text-gray-900 dark:text-white mb-2">
                    {practice.title}
                  </h3>
                  <p className="text-sm text-gray-600 dark:text-gray-400 mb-4">
                    {practice.description}
                  </p>
                  {practice.example && (
                    <div className="bg-primary-500/5 dark:bg-primary-500/10 rounded p-3 text-sm border border-primary-500/20">
                      <p><strong>What:</strong> {practice.example.what}</p>
                      <p><strong>How:</strong> {practice.example.how}</p>
                      <p><strong>Where:</strong> {practice.example.where}</p>
                      <p><strong>Outcome:</strong> {practice.example.outcome}</p>
                    </div>
                  )}
                  {practice.points && (
                    <ul className="list-disc list-inside text-sm text-gray-600 dark:text-gray-400 space-y-1">
                      {practice.points.map((point, idx) => (
                        <li key={idx}>{point}</li>
                      ))}
                    </ul>
                  )}
                </div>
              ))}
            </div>
          </motion.div>
        </div>

        {/* Next Steps */}
        <div className="mt-12 p-6 bg-primary-500/5 dark:bg-primary-500/10 rounded-lg border border-primary-500/20 dark:border-primary-500/20">
          <h3 className="text-lg font-semibold mb-2 text-primary-600 dark:text-primary-400">
            Ready to Start?
          </h3>
          <p className="text-gray-700 dark:text-gray-300">
            Check out our Best Practices guide for more detailed information on how to make the most of Ana's capabilities.
          </p>
        </div>
      </motion.div>
    </div>
  );
};

export default QuickStart;

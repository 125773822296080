import React from 'react';
import { motion } from 'framer-motion';
import ScrollHeader from '../components/ScrollHeader';

const BestPractices = () => {
  const cardClasses = `
    group p-6 rounded-lg 
    bg-light-card dark:bg-dark-card
    backdrop-blur-sm
    border border-light-border dark:border-dark-border
    transition-all duration-200 
    hover:border-primary-500/30 dark:hover:border-primary-500/30
    hover:shadow-lg hover:shadow-primary-500/5 dark:hover:shadow-primary-500/10
    h-full mb-6
  `;

  const exampleClasses = `
    bg-light-hover dark:bg-dark-hover
    backdrop-blur-sm
    rounded-lg p-4 mt-4
    border border-light-border dark:border-dark-border
  `;

  const practices = [
    {
      title: "Structured Requirements for Better Guidance",
      description: "Ana thrives when tasks are clear and requirements are well-structured. Use this framework to guide Ana effectively:",
      items: [
        { label: "What", content: "Describe the task/requirements you want Ana to perform." },
        { label: "How", content: "Provide any specific instructions, guidelines, or constraints (e.g., \"Refactor using TypeScript best practices or use regression analysis etc\")." },
        { label: "Where", content: "Provide information on the files or modules it should be working on (e.g. review the KYCApproval.java file or update MyOrderStatus.py file)" },
        { label: "Outcome", content: "Clearly define what success looks like or what is the definition of done (e.g., \"Ensure all unit tests pass and maintain 90% coverage\", \"App is fully responsive\")." }
      ],
      example: {
        title: "Example Prompt:",
        content: [
          "What: Write a Python script to calculate Fibonacci numbers.",
          "How: Use a recursive approach with memorization.",
          "Outcome: The script should compute the 100th Fibonacci number in under 1 second."
        ]
      }
    },
    {
      title: "Break Down Complex and Large Tasks",
      description: "Ana performs best when tasks are broken into smaller, manageable sub-tasks. For large or multifaceted projects:",
      items: [
        "Divide the work into sequential steps.",
        "Allocate specific sessions to each sub-task for better focus."
      ],
      example: {
        title: "Example:",
        content: [
          "Instead of \"Migrate our entire monolith to microservices,\" break it into:",
          "1. Extract the user authentication module.",
          "2. Set up a scalable service for the authentication layer.",
          "3. Test and deploy the service in isolation."
        ]
      }
    },
    {
      title: "Provide Checkpoints and Verification Steps",
      description: "Encourage Ana to validate its progress at critical points:",
      items: [
        "Define checkpoints where Ana can verify its outputs against predefined criteria.",
        "Specify how Ana should test or evaluate its work before moving forward.",
        "Ask Ana to check the errors in console or the terminal"
      ]
    },
    {
      title: "Be Specific and Explicit",
      description: "Ana excels when instructions are explicit and detailed:",
      items: [
        "Mention repositories, libraries, branches, or tools explicitly.",
        "Include steps or examples if available.",
        "No detail is \"too obvious\"; Ana will clarify if needed."
      ],
      example: {
        title: "Example:",
        content: [
          "Instead of saying, \"Fix the CI/CD pipeline,\" specify:",
          "\"Update the GitHub Actions workflow to run tests on Node.js 16 and ensure the deployment step works with Kubernetes.\""
        ]
      }
    },
    {
      title: "Reference Resources and Examples",
      description: "Ana has a learning curve and needs good context. To accelerate its learning curve and ensure it has the right context:",
      items: [
        "Share and provide links to documentation, examples, or references of prior work.",
        "If resources are unavailable, Ana can independently search, but accuracy improves with clear references.",
        "Before asking Ana to perform a task, ask if any more reference or help is needed or check its understanding and context"
      ]
    },
    {
      title: "Active Collaboration",
      description: "Treat Ana like a team member:",
      items: [
        "Engage with Ana through iterative feedback.",
        "Ask questions, clarify instructions, and redirect efforts when needed.",
        "Give feedback and best examples to Ana if you think it is not able to give right output"
      ]
    }
  ];

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <ScrollHeader title="Best Practices and Advice" />
        <p className="text-xl text-gray-600 dark:text-gray-400 mb-8">
          To maximize Ana's potential and achieve the best outcomes, consider the following best practices. Ana is fully capable of working on tasks that are as high level as "Build an Incident Management system" to specific tasks like reviewing code or calling an API.
        </p>

        {practices.map((practice, index) => (
          <div key={index} className={cardClasses}>
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
            >
              <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
                {practice.title}
              </h2>
              <p className="text-gray-600 dark:text-gray-400 mb-4">
                {practice.description}
              </p>

              {practice.items && (
                <ul className="list-disc list-inside space-y-2 text-gray-600 dark:text-gray-400 mb-4">
                  {practice.items.map((item, idx) => (
                    <li key={idx} className="ml-4">
                      {typeof item === 'string' ? (
                        item
                      ) : (
                        <span>
                          <strong className="text-primary-500 dark:text-primary-400">{item.label}:</strong> {item.content}
                        </span>
                      )}
                    </li>
                  ))}
                </ul>
              )}

              {practice.example && (
                <div className={exampleClasses}>
                  <h3 className="font-semibold text-primary-600 dark:text-primary-400 mb-2">
                    {practice.example.title}
                  </h3>
                  <div className="space-y-2 text-gray-600 dark:text-gray-400">
                    {practice.example.content.map((line, idx) => (
                      <p key={idx} className="font-mono text-sm">
                        {line}
                      </p>
                    ))}
                  </div>
                </div>
              )}
            </motion.div>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default BestPractices;

import React from 'react';
import { motion } from 'framer-motion';
import { FiCode, FiZap, FiShield, FiBox, FiCpu, FiLock } from 'react-icons/fi';
import ScrollHeader from '../components/ScrollHeader';

const Introduction = () => {
  const features = [
    {
      icon: <FiCode />,
      title: 'Build Better',
      description: 'Write high-quality code with best practices and modern patterns.'
    },
    {
      icon: <FiZap />,
      title: 'Build Faster',
      description: 'Accelerate development with AI-powered automation.'
    },
    {
      icon: <FiShield />,
      title: 'Build Secure',
      description: 'Ensure security best practices in every line of code.'
    }
  ];

  const capabilities = [
    {
      icon: <FiBox />,
      title: 'Task-Centric Intelligence',
      description: 'Ana excels at breaking down complex challenges into manageable tasks, optimized for tasks up to three Agile story points.'
    },
    {
      icon: <FiCpu />,
      title: 'Contextual Awareness',
      description: 'Understands project context deeply, from single files to entire codebases, ensuring accurate and relevant assistance.'
    },
    {
      icon: <FiLock />,
      title: 'Security-First Approach',
      description: 'Built-in security best practices and vulnerability detection in every interaction.'
    }
  ];

  const cardClasses = `
    group p-6 rounded-lg 
    bg-light-card dark:bg-dark-card
    backdrop-blur-sm
    border border-light-border dark:border-dark-border
    transition-all duration-200 
    hover:border-primary-500/30 dark:hover:border-primary-500/30
    hover:shadow-lg hover:shadow-primary-500/5 dark:hover:shadow-primary-500/10
    h-full
  `;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <div className="space-y-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <ScrollHeader title="Meet Ana: Your Autonomous AI Engineer" />
        </motion.div>
        <div className="text-xl text-gray-600 dark:text-gray-400">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.1 }}
          >
            Ana is your AI-powered engineering teammate, designed to help enterprises innovate and deliver faster
            while collaborating with and augmenting your super-performant engineering teams.
          </motion.div>
        </div>

        {/* Feature Grid */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {features.map((feature, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className={cardClasses}>
                <div className="text-2xl text-primary-500 mb-4 transition-transform group-hover:scale-110">
                  {feature.icon}
                </div>
                <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">{feature.title}</h3>
                <p className="text-gray-600 dark:text-gray-400">{feature.description}</p>
              </div>
            </motion.div>
          ))}
        </div>

        {/* What Sets Ana Apart Section */}
        <div className="text-3xl font-bold mb-8 text-gray-900 dark:text-white">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            What Sets Ana Apart?
          </motion.div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {capabilities.map((capability, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5, delay: index * 0.1 }}
              whileHover={{ scale: 1.02 }}
              whileTap={{ scale: 0.98 }}
            >
              <div className={cardClasses}>
                <div className="text-2xl text-primary-500 mb-4 transition-transform group-hover:scale-110">
                  {capability.icon}
                </div>
                <h3 className="text-lg font-semibold mb-2 text-gray-900 dark:text-white">{capability.title}</h3>
                <p className="text-gray-600 dark:text-gray-400">{capability.description}</p>
              </div>
            </motion.div>
          ))}
        </div>

        {/* Call to Action */}
        <div className="text-center py-12">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <h2 className="text-2xl font-bold mb-4 text-gray-900 dark:text-white">
              Ready to Transform Your Development Process?
            </h2>
            <p className="text-gray-600 dark:text-gray-400 mb-6">
              Start your journey with Ana today and experience the future of collaborative software engineering.
            </p>
            <div>
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                <button className="px-6 py-3 bg-primary-500 hover:bg-primary-600 text-white rounded-md transition-colors shadow-lg shadow-primary-500/20 hover:shadow-primary-500/30">
                  Get Started with Ana
                </button>
              </motion.div>
            </div>
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default Introduction;

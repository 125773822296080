import React from 'react';
import { motion } from 'framer-motion';
import { FiTerminal, FiGlobe, FiCode, FiLock, FiDatabase, FiSearch } from 'react-icons/fi';
import ScrollHeader from '../components/ScrollHeader';

const Features = () => {
  const features = [
    {
      icon: <FiTerminal />,
      title: "Terminal Access",
      description: "Execute CLI commands on your system with full control and visibility. Perfect for running scripts, managing dependencies, and performing system operations.",
      capabilities: [
        "Execute any valid CLI command",
        "Run commands in specific directories",
        "Handle long-running processes",
        "View real-time command output"
      ]
    },
    {
      icon: <FiGlobe />,
      title: "Browser Control",
      description: "Interact with websites and web applications through a Puppeteer-controlled browser, enabling automated testing and verification.",
      capabilities: [
        "Launch browser sessions",
        "Navigate web pages",
        "Click elements and input text",
        "Capture screenshots for verification"
      ]
    },
    {
      icon: <FiCode />,
      title: "Code Management",
      description: "Comprehensive tools for reading, writing, and analyzing source code across your project.",
      capabilities: [
        "Read file contents",
        "Write or update files",
        "List code definitions",
        "Search across files with regex"
      ]
    },
    {
      icon: <FiSearch />,
      title: "File Operations",
      description: "Powerful file system operations to help manage and organize your project files effectively.",
      capabilities: [
        "List files and directories",
        "Search file contents",
        "Create new files and directories",
        "Manage file structures"
      ]
    },
    {
      icon: <FiLock />,
      title: "Security Features",
      description: "Built-in security measures to ensure safe and secure operations within your development environment.",
      capabilities: [
        "Secure credential handling",
        "Safe file operations",
        "Protected system access",
        "Controlled execution environment"
      ]
    },
    {
      icon: <FiDatabase />,
      title: "Project Analysis",
      description: "Deep understanding of your project structure and codebase for informed decision-making.",
      capabilities: [
        "Analyze code patterns",
        "Identify dependencies",
        "Understand project architecture",
        "Track code definitions"
      ]
    }
  ];

  const cardClasses = `
    bg-light-card dark:bg-dark-card
    backdrop-blur-sm
    rounded-lg
    border border-light-border dark:border-dark-border
    p-6 cursor-pointer
    transition-all duration-200
    hover:border-primary-500/30 dark:hover:border-primary-500/30
    hover:shadow-lg hover:shadow-primary-500/5 dark:hover:shadow-primary-500/10
  `;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <ScrollHeader title="Features" />
        <p className="text-xl text-gray-600 dark:text-gray-400 mb-8">
          Explore Ana's powerful capabilities that help you build better, faster, and more secure applications.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {features.map((feature, index) => (
            <div key={index} className={cardClasses}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                whileHover={{ 
                  scale: 1.02,
                  transition: { duration: 0.2 }
                }}
              >
                <div className="text-2xl text-primary-500 dark:text-primary-400 mb-4">
                  {feature.icon}
                </div>
                <h2 className="text-xl font-bold mb-3 text-gray-900 dark:text-white">
                  {feature.title}
                </h2>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  {feature.description}
                </p>
                <ul className="list-disc list-inside space-y-2 text-gray-600 dark:text-gray-400">
                  {feature.capabilities.map((capability, idx) => (
                    <li key={idx}>{capability}</li>
                  ))}
                </ul>
              </motion.div>
            </div>
          ))}
        </div>

        <div className="mt-12 bg-primary-500/5 dark:bg-primary-500/10 rounded-lg border border-primary-500/20 dark:border-primary-500/20 p-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <h3 className="text-lg font-semibold mb-2 text-primary-600 dark:text-primary-400">
              Continuous Evolution
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Ana's features are continuously expanding and improving. Check back regularly for updates and new capabilities to enhance your development workflow.
            </p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Features;

import React from 'react';
import { motion } from 'framer-motion';
import { 
  FiAlertCircle,
  FiRefreshCw, 
  FiCode, 
  FiDatabase,
  FiShield,
  FiTool,
  FiGitPullRequest,
  FiCpu
} from 'react-icons/fi';
import ScrollHeader from '../components/ScrollHeader';

const ReferenceUseCases = () => {
  const useCases = [
    {
      icon: <FiAlertCircle />,
      title: "Issue Resolution",
      description: "Efficiently resolve GitHub issues and Jira tickets",
      status: "Complete",
      examples: [
        "Automated bug analysis and fixing",
        "Integration with issue tracking systems",
        "Pull request creation with detailed descriptions",
        "Automated testing of fixes"
      ]
    },
    {
      icon: <FiRefreshCw />,
      title: "Migration Projects",
      description: "Seamlessly upgrade and migrate applications",
      status: "Complete",
      examples: [
        "Framework version upgrades (e.g., Angular 13 to 18)",
        "Database migration assistance",
        "API endpoint updates",
        "Configuration file updates"
      ]
    },
    {
      icon: <FiCode />,
      title: "Modernization",
      description: "Transform legacy code into modern implementations",
      status: "In Progress",
      examples: [
        "Code refactoring to modern standards",
        "Implementation of design patterns",
        "Performance optimization",
        "Technical debt reduction"
      ]
    },
    {
      icon: <FiTool />,
      title: "Refactoring",
      description: "Improve code quality and maintainability",
      status: "In Progress",
      examples: [
        "Code structure optimization",
        "Design pattern implementation",
        "Technical debt reduction",
        "Performance improvements"
      ]
    },
    {
      icon: <FiDatabase />,
      title: "Dependencies Management",
      description: "Handle project dependencies effectively",
      status: "In Progress",
      examples: [
        "Dependency version updates",
        "Vulnerability patching",
        "Compatibility verification",
        "Package optimization"
      ]
    },
    {
      icon: <FiCpu />,
      title: "Application Development",
      description: "Build new applications and features",
      status: "In Progress",
      examples: [
        "Feature implementation",
        "Component development",
        "API integration",
        "Testing automation"
      ]
    },
    {
      icon: <FiDatabase />,
      title: "ML Modelling",
      description: "Develop and optimize machine learning models",
      status: "In Progress",
      examples: [
        "Model development assistance",
        "Data preprocessing",
        "Training pipeline setup",
        "Model optimization"
      ]
    },
    {
      icon: <FiShield />,
      title: "Threat Modelling",
      description: "Enhance application security",
      status: "In Progress",
      examples: [
        "Security vulnerability analysis",
        "Threat identification",
        "Security best practices implementation",
        "Security testing automation"
      ]
    },
    {
      icon: <FiGitPullRequest />,
      title: "Review PR",
      description: "Comprehensive pull request reviews",
      status: "Complete",
      examples: [
        "Code quality analysis",
        "Security vulnerability checks",
        "Performance impact assessment",
        "Best practices verification"
      ]
    },
    {
      icon: <FiCode />,
      title: "Test Automation",
      description: "Automate testing processes",
      status: "Complete",
      examples: [
        "Unit test generation",
        "Integration test creation",
        "Test coverage improvement",
        "Test maintenance"
      ]
    }
  ];

  const getStatusColor = (status: string) => {
    switch (status.toLowerCase()) {
      case 'complete':
        return 'bg-primary-500/10 text-primary-500 dark:bg-primary-500/20 dark:text-primary-400';
      case 'in progress':
        return 'bg-primary-500/5 text-primary-600 dark:bg-primary-500/10 dark:text-primary-500';
      default:
        return 'bg-gray-100 text-gray-800 dark:bg-gray-900 dark:text-gray-200';
    }
  };

  const cardClasses = `
    bg-light-card dark:bg-dark-card
    backdrop-blur-sm
    rounded-lg
    border border-light-border dark:border-dark-border
    p-6 cursor-pointer
    transition-all duration-200
    hover:border-primary-500/30 dark:hover:border-primary-500/30
    hover:shadow-lg hover:shadow-primary-500/5 dark:hover:shadow-primary-500/10
  `;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <ScrollHeader title="Reference Use Cases" />
        <p className="text-xl text-gray-600 dark:text-gray-400 mb-8">
          Explore real-world examples of how Ana can be used to solve various development challenges.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {useCases.map((useCase, index) => (
            <div key={index} className={cardClasses}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                whileHover={{ 
                  scale: 1.02,
                  transition: { duration: 0.2 }
                }}
              >
                <div className="flex items-center justify-between mb-4">
                  <div className="text-2xl text-primary-500 dark:text-primary-400">
                    {useCase.icon}
                  </div>
                  <span className={`px-2 py-1 rounded-full text-xs font-medium ${getStatusColor(useCase.status)}`}>
                    {useCase.status}
                  </span>
                </div>
                <h2 className="text-xl font-bold mb-3 text-gray-900 dark:text-white">
                  {useCase.title}
                </h2>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  {useCase.description}
                </p>
                <ul className="list-disc list-inside space-y-2 text-gray-600 dark:text-gray-400">
                  {useCase.examples.map((example, idx) => (
                    <li key={idx}>{example}</li>
                  ))}
                </ul>
              </motion.div>
            </div>
          ))}
        </div>

        <div className="mt-12 bg-primary-500/5 dark:bg-primary-500/10 rounded-lg border border-primary-500/20 dark:border-primary-500/20 p-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <h3 className="text-lg font-semibold mb-2 text-primary-600 dark:text-primary-400">
              Custom Use Cases
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              These are just a few examples of what Ana can do. Ana can be adapted to handle many other specific use cases based on your team's needs.
            </p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default ReferenceUseCases;

import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';
import { FiMenu, FiMoon, FiSun, FiSearch, FiX } from 'react-icons/fi';
import { searchIndex } from '../utils/searchIndex';

interface LayoutProps {
  children: React.ReactNode;
}

interface SearchResult {
  title: string;
  path: string;
  section: string;
  content: string;
  preview: string;
}

const Layout: React.FC<LayoutProps> = ({ children }) => {
  const [isDarkMode, setIsDarkMode] = React.useState(() => {
    const storedTheme = localStorage.getItem('theme');
    if (storedTheme !== null) {
      return storedTheme === 'dark';
    }
    return window.matchMedia('(prefers-color-scheme: dark)').matches;
  });
  const [isSidebarOpen, setSidebarOpen] = React.useState(() => {
    const storedSidebarState = localStorage.getItem('sidebarOpen');
    if (storedSidebarState !== null) {
      return storedSidebarState === 'true';
    }
    return window.innerWidth >= 768;
  });
  const [searchQuery, setSearchQuery] = React.useState('');
  const [searchResults, setSearchResults] = React.useState<SearchResult[]>([]);
  const [isSearchFocused, setIsSearchFocused] = React.useState(false);
  const [mobileSearchQuery, setMobileSearchQuery] = React.useState('');
  const [mobileSearchResults, setMobileSearchResults] = React.useState<SearchResult[]>([]);
  const [isMobileSearchFocused, setIsMobileSearchFocused] = React.useState(false);
  const searchRef = React.useRef<HTMLDivElement>(null);
  const mobileSearchRef = React.useRef<HTMLDivElement>(null);
  const location = useLocation();
  const navigate = useNavigate();

  const navItems = [
    {
      section: 'Getting Started',
      items: [
        { title: 'Introduction', path: '/' },
        { title: 'Quick Start', path: '/quickstart' },
        { title: 'Best Practices', path: '/best-practices' }
      ]
    },
    {
      section: 'Features & Integration',
      items: [
        { title: 'Features', path: '/features' },
        { title: 'Integration', path: '/integration' }
      ]
    },
    {
      section: 'Learning',
      items: [
        { title: 'Tutorials', path: '/tutorials' },
        { title: 'Common Issues', path: '/common-issues' }
      ]
    },
    {
      section: 'Use Cases',
      items: [
        { title: 'Reference Use Cases', path: '/reference-use-cases' }
      ]
    }
  ];

  const handleSearch = (query: string, isMobile: boolean = false) => {
    const setQuery = isMobile ? setMobileSearchQuery : setSearchQuery;
    const setResults = isMobile ? setMobileSearchResults : setSearchResults;
    
    setQuery(query);
    if (query.trim() === '') {
      setResults([]);
      return;
    }

    const results: SearchResult[] = [];
    const searchTerm = query.toLowerCase();

    searchIndex.forEach(page => {
      const titleMatch = page.title.toLowerCase().includes(searchTerm);
      const contentMatch = page.content.toLowerCase().includes(searchTerm);

      if (titleMatch || contentMatch) {
        let preview = '';
        if (contentMatch) {
          const matchIndex = page.content.toLowerCase().indexOf(searchTerm);
          const startIndex = Math.max(0, matchIndex - 50);
          const endIndex = Math.min(page.content.length, matchIndex + searchTerm.length + 50);
          preview = page.content.slice(startIndex, endIndex);
          
          if (startIndex > 0) preview = '...' + preview;
          if (endIndex < page.content.length) preview = preview + '...';
        }

        results.push({
          title: page.title,
          path: page.path,
          section: page.section,
          content: page.content,
          preview: preview || page.content.slice(0, 100) + '...'
        });
      }
    });

    setResults(results);
  };

  const handleSearchResultClick = (path: string, isMobile: boolean = false) => {
    navigate(path);
    if (isMobile) {
      setMobileSearchQuery('');
      setMobileSearchResults([]);
      setIsMobileSearchFocused(false);
      setSidebarOpen(false);
      localStorage.setItem('sidebarOpen', 'false');
    } else {
      setSearchQuery('');
      setSearchResults([]);
      setIsSearchFocused(false);
      if (window.innerWidth < 768) {
        setSidebarOpen(false);
        localStorage.setItem('sidebarOpen', 'false');
      }
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Function to highlight matching text
  const highlightMatch = (text: string, query: string) => {
    if (!query.trim()) return text;

    const parts = text.split(new RegExp(`(${query})`, 'gi'));
    return parts.map((part, i) => 
      part.toLowerCase() === query.toLowerCase() ? 
        <span key={i} className="bg-primary-500/20 text-primary-600 dark:text-primary-400 font-medium">{part}</span> : 
        part
    );
  };

  // Close search results when clicking outside
  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (searchRef.current && !searchRef.current.contains(event.target as Node)) {
        setIsSearchFocused(false);
      }
      if (mobileSearchRef.current && !mobileSearchRef.current.contains(event.target as Node)) {
        setIsMobileSearchFocused(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const toggleTheme = () => {
    setIsDarkMode(prev => {
      const newTheme = !prev;
      localStorage.setItem('theme', newTheme ? 'dark' : 'light');
      return newTheme;
    });
  };

  const toggleSidebar = () => {
    setSidebarOpen(prev => {
      const newState = !prev;
      localStorage.setItem('sidebarOpen', String(newState));
      return newState;
    });
  };

  React.useEffect(() => {
    if (isDarkMode) {
      document.documentElement.classList.add('dark');
      document.documentElement.classList.remove('light');
    } else {
      document.documentElement.classList.add('light');
      document.documentElement.classList.remove('dark');
    }
  }, [isDarkMode]);

  // Listen for system theme changes
  React.useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e: MediaQueryListEvent) => {
      if (localStorage.getItem('theme') === null) {
        setIsDarkMode(e.matches);
      }
    };
    
    mediaQuery.addEventListener('change', handleChange);
    return () => mediaQuery.removeEventListener('change', handleChange);
  }, []);

  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setSidebarOpen(false);
        localStorage.setItem('sidebarOpen', 'false');
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleNavigation = (path: string) => {
    if (window.innerWidth < 768) {
      setSidebarOpen(false);
      localStorage.setItem('sidebarOpen', 'false');
    }
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <div className="min-h-screen bg-light-background dark:bg-dark-background">
      <div className="flex h-screen">
        {/* Desktop Sidebar */}
        <div 
          className={`hidden md:block fixed inset-y-0 left-0 w-64 bg-light-surface dark:bg-dark-surface border-r border-light-border dark:border-dark-border transform transition-all duration-200 ease-in-out z-40 ${
            isSidebarOpen ? 'translate-x-0' : '-translate-x-full'
          }`}
          style={{ top: '28px', height: 'calc(100% - 28px)' }}
        >
          <div className="sticky top-0 bg-light-surface dark:bg-dark-surface z-10 border-b border-light-border dark:border-dark-border">
            <div className="p-6">
              <div className="flex items-center gap-3">
                <img 
                  src="/images/ana_logo.png" 
                  alt="ANA Logo" 
                  className="h-8 w-auto"
                />
                <img 
                  src={isDarkMode ? "/images/ana_text_dark.svg" : "/images/ana_text.svg"}
                  alt="Ana" 
                  className="h-6 w-auto"
                />
              </div>
              <p className="text-lg text-gray-500 dark:text-gray-400 mt-4">Your AI Engineering Teammate</p>
            </div>

            <div className="px-4 pb-4" ref={searchRef}>
              <div className="relative">
                <div className="absolute left-3 top-1/2 -translate-y-1/2 text-gray-400 dark:text-gray-500 z-10">
                  <FiSearch size={16} />
                </div>
                <input
                  type="text"
                  placeholder="Search documentation..."
                  value={searchQuery}
                  onChange={(e) => handleSearch(e.target.value)}
                  onFocus={() => setIsSearchFocused(true)}
                  className="w-full pl-10 pr-4 py-2 bg-light-hover dark:bg-dark-card rounded-md text-sm text-gray-900 dark:text-white placeholder-gray-500 dark:placeholder-gray-400 transition-colors duration-200 focus:outline-none focus:ring-2 focus:ring-primary-500"
                />

                {/* Desktop Search Results */}
                {isSearchFocused && searchResults.length > 0 && (
                  <motion.div
                    initial={{ opacity: 0, y: 10 }}
                    animate={{ opacity: 1, y: 0 }}
                    style={{
                      position: 'absolute',
                      left: 0,
                      right: 0,
                      marginTop: '0.5rem',
                      padding: '0.5rem 0',
                      backgroundColor: isDarkMode ? 'var(--dark-surface)' : 'var(--light-surface)',
                      border: `1px solid ${isDarkMode ? 'var(--dark-border)' : 'var(--light-border)'}`,
                      borderRadius: '0.5rem',
                      boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                      maxHeight: '60vh',
                      overflow: 'auto',
                      zIndex: 50
                    }}
                  >
                    {searchResults.map((result, index) => (
                      <button
                        key={index}
                        onClick={() => handleSearchResultClick(result.path)}
                        className="w-full px-4 py-3 text-left hover:bg-light-hover dark:hover:bg-dark-hover transition-colors duration-200 border-b border-light-border dark:border-dark-border last:border-0"
                      >
                        <div className="text-sm font-medium text-gray-900 dark:text-white mb-1">
                          {highlightMatch(result.title, searchQuery)}
                        </div>
                        <div className="text-xs text-primary-600 dark:text-primary-400 mb-2">
                          {result.section}
                        </div>
                        {result.preview && (
                          <div className="text-xs text-gray-600 dark:text-gray-400 line-clamp-2">
                            {highlightMatch(result.preview, searchQuery)}
                          </div>
                        )}
                      </button>
                    ))}
                  </motion.div>
                )}
              </div>
            </div>
          </div>

          <nav className="h-[calc(100vh-200px)] overflow-y-auto">
            <div className="px-4 py-4">
              {navItems.map((section, idx) => (
                <div key={idx} className="mb-6">
                  <h2 className="text-xs font-semibold text-gray-600 dark:text-gray-400 uppercase tracking-wide mb-2 transition-colors duration-200">
                    {section.section}
                  </h2>
                  {section.items.map((item, itemIdx) => (
                    <Link
                      key={itemIdx}
                      to={item.path}
                      onClick={() => handleNavigation(item.path)}
                      className={`block px-4 py-2 text-sm rounded-md mb-1 transition-all duration-200 ${
                        location.pathname === item.path
                          ? 'bg-primary-500/10 dark:bg-primary-500/20 text-primary-500 dark:text-primary-400 border border-primary-500/20'
                          : 'text-gray-600 dark:text-gray-400 hover:bg-primary-500/5 dark:hover:bg-dark-hover hover:text-primary-500 dark:hover:text-primary-400'
                      }`}
                    >
                      {item.title}
                    </Link>
                  ))}
                </div>
              ))}
            </div>
          </nav>
        </div>

        {/* Mobile Menu */}
        <AnimatePresence>
          {isSidebarOpen && (
            <div className={`fixed inset-0 z-50 md:hidden ${isDarkMode ? 'bg-black' : 'bg-white'}`} style={{ top: '28px', height: 'calc(100% - 28px)' }}>
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.2 }}
                style={{ height: '100%' }}
              >
                <div className="h-full flex flex-col">
                  <div className="flex justify-end p-4">
                    <button
                      onClick={() => {
                        setSidebarOpen(false);
                        localStorage.setItem('sidebarOpen', 'false');
                      }}
                      className={`p-2 ${isDarkMode ? 'text-white/60 hover:text-white' : 'text-black/60 hover:text-black'}`}
                    >
                      <FiX className="w-8 h-8" />
                    </button>
                  </div>

                  {/* Mobile Search */}
                  <div className="px-8 mb-8" ref={mobileSearchRef}>
                    <motion.div
                      initial={{ opacity: 0, y: -20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: 0.2 }}
                    >
                      <div className="relative">
                        <div className={`absolute left-4 top-1/2 -translate-y-1/2 z-10 ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
                          <FiSearch size={20} />
                        </div>
                        <input
                          type="text"
                          placeholder="Search documentation..."
                          value={mobileSearchQuery}
                          onChange={(e) => handleSearch(e.target.value, true)}
                          onFocus={() => setIsMobileSearchFocused(true)}
                          className={`w-full pl-12 pr-4 py-3 rounded-xl text-lg transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-[#FF3D00] ${
                            isDarkMode 
                              ? 'bg-white/10 text-white placeholder-white/60' 
                              : 'bg-black/10 text-black placeholder-black/60'
                          }`}
                        />

                        {/* Mobile Search Results */}
                        {isMobileSearchFocused && mobileSearchResults.length > 0 && (
                          <motion.div
                            initial={{ opacity: 0, y: 10 }}
                            animate={{ opacity: 1, y: 0 }}
                            style={{
                              position: 'absolute',
                              left: 0,
                              right: 0,
                              marginTop: '0.5rem',
                              padding: '0.5rem 0',
                              backgroundColor: isDarkMode ? 'rgba(0, 0, 0, 0.9)' : 'rgba(255, 255, 255, 0.9)',
                              border: `1px solid ${isDarkMode ? 'rgba(255, 255, 255, 0.2)' : 'rgba(0, 0, 0, 0.2)'}`,
                              borderRadius: '0.75rem',
                              boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
                              maxHeight: '50vh',
                              overflow: 'auto'
                            }}
                          >
                            {mobileSearchResults.map((result, index) => (
                              <button
                                key={index}
                                onClick={() => handleSearchResultClick(result.path, true)}
                                className={`w-full px-4 py-4 text-left border-b last:border-0 backdrop-blur-sm ${
                                  isDarkMode 
                                    ? 'border-white/10 hover:bg-white/5' 
                                    : 'border-black/10 hover:bg-black/5'
                                }`}
                              >
                                <div className={`text-lg font-bold mb-1 ${isDarkMode ? 'text-white' : 'text-black'}`}>
                                  {highlightMatch(result.title, mobileSearchQuery)}
                                </div>
                                <div className="text-sm text-[#FF3D00] mb-2">
                                  {result.section}
                                </div>
                                {result.preview && (
                                  <div className={`text-sm line-clamp-2 ${isDarkMode ? 'text-white/60' : 'text-black/60'}`}>
                                    {highlightMatch(result.preview, mobileSearchQuery)}
                                  </div>
                                )}
                              </button>
                            ))}
                          </motion.div>
                        )}
                      </div>
                    </motion.div>
                  </div>

                  <div className="flex-1 overflow-y-auto px-8">
                    {navItems.map((section, idx) => (
                      <div key={idx} className="mb-8">
                        <motion.div
                          initial={{ opacity: 0, y: 20 }}
                          animate={{ opacity: 1, y: 0 }}
                          transition={{ delay: idx * 0.1 }}
                        >
                          <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-black'}`}>
                            {section.section}
                          </h2>
                          <div className="space-y-4">
                            {section.items.map((item, itemIdx) => (
                              <div key={itemIdx}>
                                <motion.div
                                  initial={{ opacity: 0, x: -20 }}
                                  animate={{ opacity: 1, x: 0 }}
                                  transition={{ delay: (idx * 0.1) + (itemIdx * 0.05) }}
                                >
                                  <Link
                                    to={item.path}
                                    onClick={() => handleNavigation(item.path)}
                                    className="flex items-start gap-2 group"
                                  >
                                    <span className={`mt-2 ${
                                      location.pathname === item.path 
                                        ? 'text-[#FF3D00]' 
                                        : isDarkMode
                                          ? 'text-white/60 group-hover:text-[#FF3D00]'
                                          : 'text-black/60 group-hover:text-[#FF3D00]'
                                    }`}>•</span>
                                    <span className={`text-xl font-bold transition-colors ${
                                      location.pathname === item.path
                                        ? 'text-[#FF3D00]'
                                        : isDarkMode
                                          ? 'text-white/80 group-hover:text-[#FF3D00]'
                                          : 'text-black/80 group-hover:text-[#FF3D00]'
                                    }`}>
                                      {item.title}
                                    </span>
                                  </Link>
                                </motion.div>
                              </div>
                            ))}
                          </div>
                        </motion.div>
                      </div>
                    ))}

                    <div className="mt-8 mb-8">
                      <motion.div
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ delay: 0.5 }}
                      >
                        <button
                          onClick={() => {
                            toggleTheme();
                            setSidebarOpen(false);
                            localStorage.setItem('sidebarOpen', 'false');
                          }}
                          className={`flex items-center gap-3 transition-colors ${
                            isDarkMode 
                              ? 'text-white/80 hover:text-[#FF3D00]' 
                              : 'text-black/80 hover:text-[#FF3D00]'
                          }`}
                        >
                          {isDarkMode ? (
                            <>
                              <FiSun className="w-6 h-6" />
                              <span className="text-xl font-bold">Light Mode</span>
                            </>
                          ) : (
                            <>
                              <FiMoon className="w-6 h-6" />
                              <span className="text-xl font-bold">Dark Mode</span>
                            </>
                          )}
                        </button>
                      </motion.div>
                    </div>
                  </div>
                </div>
              </motion.div>
            </div>
          )}
        </AnimatePresence>

        {/* Main Content */}
        <div className={`flex-1 w-full transition-all duration-200 ${isSidebarOpen ? 'md:ml-64' : 'ml-0'}`}>
          <header className={`fixed h-16 bg-light-surface dark:bg-dark-surface border-b border-light-border dark:border-dark-border flex items-center px-6 z-20 transition-all duration-200 ${
            isSidebarOpen ? 'md:left-64 md:right-0' : 'left-0 right-0'
          }`} style={{ top: '28px' }}> {/* Account for banner height */}
            {/* Mobile Header */}
            <div className="flex items-center justify-between w-full md:hidden">
              <button
                onClick={() => {
                  setSidebarOpen(true);
                  localStorage.setItem('sidebarOpen', 'true');
                }}
                className="p-2"
              >
                <FiMenu className="w-6 h-6 text-gray-600 dark:text-gray-400" />
              </button>

              <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 flex items-center gap-3">
                <img 
                  src="/images/ana_logo.png" 
                  alt="ANA Logo" 
                  className="h-7 w-auto"
                />
                <img 
                  src={isDarkMode ? "/images/ana_text_dark.svg" : "/images/ana_text.svg"}
                  alt="Ana" 
                  className="h-6 w-auto"
                />
              </div>

              <div className="w-6" /> {/* Spacer for centering */}
            </div>

            {/* Desktop Header */}
            <div className="hidden md:flex items-center justify-between w-full">
              {/* Left side */}
              <div className="flex items-center gap-4">
                <button
                  onClick={toggleSidebar}
                  className="p-2 rounded-md hover:bg-light-hover dark:hover:bg-dark-hover transition-colors duration-200"
                >
                  <FiMenu className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                </button>

                {!isSidebarOpen && (
                  <div className="flex items-center gap-3">
                    <img 
                      src="/images/ana_logo.png" 
                      alt="ANA Logo" 
                      className="h-8 w-auto"
                    />
                    <img 
                      src={isDarkMode ? "/images/ana_text_dark.svg" : "/images/ana_text.svg"}
                      alt="Ana" 
                      className="h-6 w-auto"
                    />
                  </div>
                )}
              </div>

              {/* Center - Empty space for ScrollHeader */}
              <div className="flex-1"></div>

              {/* Right side */}
              <button
                onClick={toggleTheme}
                className="p-2 rounded-md hover:bg-light-hover dark:hover:bg-dark-hover transition-colors duration-200"
              >
                <AnimatePresence mode="wait" initial={false}>
                  <motion.div
                    key={isDarkMode ? 'dark' : 'light'}
                    initial={{ y: -20, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 20, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                  >
                    {isDarkMode ? (
                      <FiSun className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                    ) : (
                      <FiMoon className="w-5 h-5 text-gray-600 dark:text-gray-400" />
                    )}
                  </motion.div>
                </AnimatePresence>
              </button>
            </div>
          </header>

          <main className="p-6 bg-light-background dark:bg-dark-background mt-[88px] md:mt-[44px]"> {/* Adjusted margin-top for mobile sticky header */}
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;

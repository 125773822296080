import React from 'react';
import { motion } from 'framer-motion';
import { 
  FiGithub, 
  FiServer, 
  FiMessageSquare, 
  FiShield, 
  FiPieChart,
  FiCloud 
} from 'react-icons/fi';
import ScrollHeader from '../components/ScrollHeader';

const Integration = () => {
  const integrationCategories = [
    {
      icon: <FiGithub />,
      title: "Version Control and Repository Management",
      description: "Seamlessly integrate with popular version control platforms.",
      tools: [
        "GitHub",
        "GitLab",
        "Bitbucket"
      ]
    },
    {
      icon: <FiServer />,
      title: "Project Management Tools",
      description: "Keep your tasks and sprints organized with project management integrations.",
      tools: [
        "Jira",
        "Asana",
        "Trello",
        "Notion"
      ]
    },
    {
      icon: <FiMessageSquare />,
      title: "Collaboration and Communication",
      description: "Enable team-wide collaboration through communication tool integrations.",
      tools: [
        "Slack",
        "Microsoft Teams",
        "Discord"
      ]
    },
    {
      icon: <FiServer />,
      title: "Code and CI/CD Tools",
      description: "Enhance your development pipelines and automate workflows.",
      tools: [
        "Docker",
        "Kubernetes",
        "CircleCI",
        "Jenkins"
      ]
    },
    {
      icon: <FiShield />,
      title: "Security and Compliance",
      description: "Ensure compliance and manage vulnerabilities with security tool integrations.",
      tools: [
        "SonarQube",
        "Snyk",
        "Checkmarx"
      ]
    },
    {
      icon: <FiPieChart />,
      title: "Data and Visualization",
      description: "Connect with data engineering and analytics tools.",
      tools: [
        "Tableau",
        "Looker",
        "Google Sheets"
      ]
    },
    {
      icon: <FiCloud />,
      title: "Cloud Platforms",
      description: "Streamline your cloud-based workflows with major platform integrations.",
      tools: [
        "AWS",
        "Google Cloud Platform (GCP)",
        "Microsoft Azure"
      ]
    }
  ];

  const cardClasses = `
    bg-light-card dark:bg-dark-card
    backdrop-blur-sm
    rounded-lg
    border border-light-border dark:border-dark-border
    p-6 cursor-pointer
    transition-all duration-200
    hover:border-primary-500/30 dark:hover:border-primary-500/30
    hover:shadow-lg hover:shadow-primary-500/5 dark:hover:shadow-primary-500/10
  `;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <ScrollHeader title="Integrations" />
        <p className="text-xl text-gray-600 dark:text-gray-400 mb-8">
          Ana seamlessly integrates with your favorite tools and platforms, enabling smooth workflows across your software ecosystem.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {integrationCategories.map((category, index) => (
            <div key={index} className={cardClasses}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                whileHover={{ 
                  scale: 1.02,
                  transition: { duration: 0.2 }
                }}
              >
                <div className="text-2xl text-primary-500 dark:text-primary-400 mb-4">
                  {category.icon}
                </div>
                <h2 className="text-xl font-bold mb-3 text-gray-900 dark:text-white">
                  {category.title}
                </h2>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  {category.description}
                </p>
                <ul className="list-disc list-inside space-y-2 text-gray-600 dark:text-gray-400">
                  {category.tools.map((tool, idx) => (
                    <li key={idx}>{tool}</li>
                  ))}
                </ul>
              </motion.div>
            </div>
          ))}
        </div>

        <div className="mt-12 bg-primary-500/5 dark:bg-primary-500/10 rounded-lg border border-primary-500/20 dark:border-primary-500/20 p-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <h3 className="text-lg font-semibold mb-2 text-primary-600 dark:text-primary-400">
              Always Expanding
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              Ana's integrations are continuously growing! If there's a specific tool your team relies on, reach out—we're committed to expanding Ana's capabilities to fit your workflows.
            </p>
            <div className="mt-4">
              <p className="text-sm text-gray-600 dark:text-gray-400">
                Note: Some integrations might only be available to selected customers. Contact our support team for more information.
              </p>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Integration;

import React from 'react';
import { motion } from 'framer-motion';
import { 
  FiAlertTriangle, 
  FiCpu, 
  FiLock, 
  FiZap,
  FiRefreshCw,
  FiTerminal
} from 'react-icons/fi';
import ScrollHeader from '../components/ScrollHeader';

const CommonIssues = () => {
  const issues = [
    {
      icon: <FiCpu />,
      title: "Task Scope Limitations",
      problem: "Ana may struggle with very large or complex tasks.",
      solutions: [
        "Break down tasks into smaller, manageable pieces (aim for 3 story points or less)",
        "Focus on specific, well-defined requirements",
        "Use subtasks for complex projects"
      ]
    },
    {
      icon: <FiRefreshCw />,
      title: "Context Management",
      problem: "Ana has a context token limit that may impact performance on extensive codebases.",
      solutions: [
        "Use placeholders to conserve token usage",
        "Switch to larger context model when available",
        "Break down large files into smaller components",
        "Focus on relevant sections of code"
      ]
    },
    {
      icon: <FiAlertTriangle />,
      title: "Output Accuracy",
      problem: "Ana may occasionally produce results that need refinement.",
      solutions: [
        "Always review and test Ana's outputs",
        "Provide clear requirements and constraints",
        "Use the feedback system to improve accuracy",
        "Verify critical changes before implementation"
      ]
    },
    {
      icon: <FiLock />,
      title: "Security Considerations",
      problem: "Handling sensitive information and credentials requires careful attention.",
      solutions: [
        "Use Ana's secure credential-sharing options",
        "Avoid sharing sensitive data in plain text",
        "Utilize the secrets manager for sensitive information",
        "Follow security best practices for your organization"
      ]
    },
    {
      icon: <FiZap />,
      title: "Performance Issues",
      problem: "Long-running tasks or heavy computations may impact performance.",
      solutions: [
        "Optimize resource-intensive operations",
        "Use appropriate timeouts for long-running tasks",
        "Consider breaking down heavy computations",
        "Monitor system resources during execution"
      ]
    },
    {
      icon: <FiTerminal />,
      title: "Command Execution",
      problem: "Some commands may not execute as expected in different environments.",
      solutions: [
        "Ensure commands are compatible with the target system",
        "Check environment variables and paths",
        "Verify permissions for command execution",
        "Use absolute paths when necessary"
      ]
    }
  ];

  const cardClasses = `
    bg-light-card dark:bg-dark-card
    backdrop-blur-sm
    rounded-lg
    border border-light-border dark:border-dark-border
    p-6 cursor-pointer
    transition-all duration-200
    hover:border-primary-500/30 dark:hover:border-primary-500/30
    hover:shadow-lg hover:shadow-primary-500/5 dark:hover:shadow-primary-500/10
  `;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <ScrollHeader title="Common Issues" />
        <p className="text-xl text-gray-600 dark:text-gray-400 mb-8">
          Learn about common challenges you might encounter while using Ana and how to resolve them effectively.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {issues.map((issue, index) => (
            <div key={index} className={cardClasses}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                whileHover={{ 
                  scale: 1.02,
                  transition: { duration: 0.2 }
                }}
              >
                <div className="text-2xl text-primary-500 dark:text-primary-400 mb-4">
                  {issue.icon}
                </div>
                <h2 className="text-xl font-bold mb-3 text-gray-900 dark:text-white">
                  {issue.title}
                </h2>
                <div className="mb-4">
                  <h3 className="text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">
                    Problem:
                  </h3>
                  <p className="text-gray-600 dark:text-gray-400">
                    {issue.problem}
                  </p>
                </div>
                <div>
                  <h3 className="text-sm font-semibold text-gray-700 dark:text-gray-300 mb-2">
                    Solutions:
                  </h3>
                  <ul className="list-disc list-inside space-y-2 text-gray-600 dark:text-gray-400">
                    {issue.solutions.map((solution, idx) => (
                      <li key={idx}>{solution}</li>
                    ))}
                  </ul>
                </div>
              </motion.div>
            </div>
          ))}
        </div>

        <div className="mt-12 bg-primary-500/5 dark:bg-primary-500/10 rounded-lg border border-primary-500/20 dark:border-primary-500/20 p-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <h3 className="text-lg font-semibold mb-2 text-primary-600 dark:text-primary-400">
              Need Additional Help?
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              If you encounter issues not covered here, reach out to our support team at support@openana.ai or join our community on Slack and Discord for real-time assistance.
            </p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default CommonIssues;

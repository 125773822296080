import React from 'react';
import { motion } from 'framer-motion';
import { 
  FiCode, 
  FiGitPullRequest, 
  FiRefreshCw, 
  FiShield,
  FiDatabase,
  FiTool
} from 'react-icons/fi';
import ScrollHeader from '../components/ScrollHeader';

const Tutorials = () => {
  const tutorialCategories = [
    {
      icon: <FiCode />,
      title: "Getting Started",
      description: "Learn the basics of working with Ana",
      tutorials: [
        {
          name: "Setting Up Ana",
          difficulty: "Beginner",
          duration: "10 mins",
          link: "#setup"
        },
        {
          name: "Your First Task with Ana",
          difficulty: "Beginner",
          duration: "15 mins",
          link: "#first-task"
        },
        {
          name: "Understanding Ana's Capabilities",
          difficulty: "Beginner",
          duration: "20 mins",
          link: "#capabilities"
        }
      ]
    },
    {
      icon: <FiGitPullRequest />,
      title: "Code Management",
      description: "Master code-related tasks with Ana",
      tutorials: [
        {
          name: "Code Review with Ana",
          difficulty: "Intermediate",
          duration: "25 mins",
          link: "#code-review"
        },
        {
          name: "Automated Testing",
          difficulty: "Intermediate",
          duration: "30 mins",
          link: "#testing"
        },
        {
          name: "Refactoring Code",
          difficulty: "Advanced",
          duration: "45 mins",
          link: "#refactoring"
        }
      ]
    },
    {
      icon: <FiRefreshCw />,
      title: "Migration & Modernization",
      description: "Learn how to upgrade and modernize your applications",
      tutorials: [
        {
          name: "Framework Migration",
          difficulty: "Advanced",
          duration: "60 mins",
          link: "#migration"
        },
        {
          name: "Legacy Code Modernization",
          difficulty: "Advanced",
          duration: "45 mins",
          link: "#modernization"
        }
      ]
    },
    {
      icon: <FiShield />,
      title: "Security",
      description: "Implement secure coding practices with Ana",
      tutorials: [
        {
          name: "Security Analysis",
          difficulty: "Intermediate",
          duration: "30 mins",
          link: "#security"
        },
        {
          name: "Threat Modeling",
          difficulty: "Advanced",
          duration: "45 mins",
          link: "#threat-modeling"
        }
      ]
    },
    {
      icon: <FiDatabase />,
      title: "Data & API",
      description: "Work with data and integrate APIs effectively",
      tutorials: [
        {
          name: "API Integration",
          difficulty: "Intermediate",
          duration: "35 mins",
          link: "#api"
        },
        {
          name: "Data Processing",
          difficulty: "Intermediate",
          duration: "40 mins",
          link: "#data"
        }
      ]
    },
    {
      icon: <FiTool />,
      title: "Advanced Topics",
      description: "Explore advanced use cases and features",
      tutorials: [
        {
          name: "ML Model Development",
          difficulty: "Advanced",
          duration: "90 mins",
          link: "#ml-model"
        },
        {
          name: "Custom Automation",
          difficulty: "Advanced",
          duration: "60 mins",
          link: "#automation"
        }
      ]
    }
  ];

  const getDifficultyColor = (difficulty: string) => {
    switch (difficulty.toLowerCase()) {
      case 'beginner':
        return 'text-green-500 dark:text-green-400';
      case 'intermediate':
        return 'text-primary-500 dark:text-primary-400';
      case 'advanced':
        return 'text-red-500 dark:text-red-400';
      default:
        return 'text-gray-600 dark:text-gray-400';
    }
  };

  const cardClasses = `
    bg-light-card dark:bg-dark-card
    backdrop-blur-sm
    rounded-lg
    border border-light-border dark:border-dark-border
    p-6 cursor-pointer
    transition-all duration-200
    hover:border-primary-500/30 dark:hover:border-primary-500/30
    hover:shadow-lg hover:shadow-primary-500/5 dark:hover:shadow-primary-500/10
  `;

  return (
    <div className="max-w-4xl mx-auto space-y-8">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        <ScrollHeader title="Tutorials" />
        <p className="text-xl text-gray-600 dark:text-gray-400 mb-8">
          Learn how to make the most of Ana with our comprehensive tutorials, from basic setup to advanced use cases.
        </p>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          {tutorialCategories.map((category, index) => (
            <div key={index} className={cardClasses}>
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                whileHover={{ 
                  scale: 1.02,
                  transition: { duration: 0.2 }
                }}
              >
                <div className="text-2xl text-primary-500 dark:text-primary-400 mb-4">
                  {category.icon}
                </div>
                <h2 className="text-xl font-bold mb-3 text-gray-900 dark:text-white">
                  {category.title}
                </h2>
                <p className="text-gray-600 dark:text-gray-400 mb-4">
                  {category.description}
                </p>
                <div className="space-y-4">
                  {category.tutorials.map((tutorial, idx) => (
                    <div 
                      key={idx}
                      className="p-3 bg-light-hover dark:bg-dark-hover backdrop-blur-sm rounded-lg border border-light-border dark:border-dark-border"
                    >
                      <h3 className="font-semibold text-gray-900 dark:text-white mb-2">
                        {tutorial.name}
                      </h3>
                      <div className="flex items-center space-x-4 text-sm">
                        <span className={getDifficultyColor(tutorial.difficulty)}>
                          {tutorial.difficulty}
                        </span>
                        <span className="text-gray-600 dark:text-gray-400">
                          {tutorial.duration}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </motion.div>
            </div>
          ))}
        </div>

        <div className="mt-12 bg-primary-500/5 dark:bg-primary-500/10 rounded-lg border border-primary-500/20 dark:border-primary-500/20 p-6">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5, delay: 0.3 }}
          >
            <h3 className="text-lg font-semibold mb-2 text-primary-600 dark:text-primary-400">
              More Tutorials Coming Soon
            </h3>
            <p className="text-gray-700 dark:text-gray-300">
              We're constantly adding new tutorials and improving existing ones. Check back regularly for updates and new learning materials.
            </p>
          </motion.div>
        </div>
      </motion.div>
    </div>
  );
};

export default Tutorials;

interface PageContent {
  title: string;
  path: string;
  section: string;
  content: string;
}

export const searchIndex: PageContent[] = [
  {
    title: "Introduction",
    path: "/",
    section: "Getting Started",
    content: "Meet Ana: Your Autonomous AI Engineer. Ana is your AI-powered engineering teammate, designed to help enterprises innovate and deliver faster while collaborating with and augmenting your super-performant engineering teams. Build Better: Write high-quality code with best practices and modern patterns. Build Faster: Accelerate development with AI-powered automation. Build Secure: Ensure security best practices in every line of code."
  },
  {
    title: "Quick Start",
    path: "/quickstart",
    section: "Getting Started",
    content: "Get started with Ana in minutes. Choose your preferred way to access Ana and begin collaborating with your AI engineering teammate. Installation Options: Ana is available as an IDE Plugin or Web Application. IDE Plugin: Install directly in your development environment through VS Code or your preferred IDE. Web Application: Access through app.openana.ai with instant collaboration capabilities."
  },
  {
    title: "Best Practices",
    path: "/best-practices",
    section: "Getting Started",
    content: "Maximize Ana's potential with best practices. Structure your tasks using What/How/Where/Outcome format. Break down complex tasks into manageable pieces. Provide clear requirements and context. Use checkpoints for validation. Share relevant documentation and examples. Engage in active collaboration with iterative feedback."
  },
  {
    title: "Features",
    path: "/features",
    section: "Features & Integration",
    content: "Explore Ana's powerful capabilities. Terminal Access: Execute CLI commands with full control. Browser Control: Interact with web applications through automated testing. Code Management: Comprehensive tools for reading, writing, and analyzing code. File Operations: Manage project files effectively. Security Features: Built-in security measures for safe operations."
  },
  {
    title: "Integration",
    path: "/integration",
    section: "Features & Integration",
    content: "Seamlessly integrate Ana with your tools and platforms. Version Control: GitHub, GitLab, Bitbucket integration. Project Management: Jira, Asana, Trello integration. Communication: Slack, Microsoft Teams, Discord integration. CI/CD: Docker, Kubernetes, CircleCI, Jenkins integration. Cloud Platforms: AWS, GCP, Azure integration."
  },
  {
    title: "Tutorials",
    path: "/tutorials",
    section: "Learning",
    content: "Learn through comprehensive tutorials. Getting Started tutorials for basic setup and first tasks. Code Management tutorials for review and testing. Migration tutorials for upgrading applications. Security tutorials for implementing best practices. API Integration tutorials for connecting services. Advanced topics including ML Model development."
  },
  {
    title: "Common Issues",
    path: "/common-issues",
    section: "Learning",
    content: "Resolve common challenges effectively. Task Scope Limitations: Handle large tasks efficiently. Context Management: Optimize token usage and context handling. Output Accuracy: Ensure precise results with proper validation. Security Considerations: Handle sensitive information safely. Performance Issues: Optimize resource-intensive operations."
  },
  {
    title: "Reference Use Cases",
    path: "/reference-use-cases",
    section: "Use Cases",
    content: "Real-world examples of Ana's capabilities. Issue Resolution: Automated bug fixing and issue tracking. Migration Projects: Framework upgrades and database migrations. Modernization: Legacy code transformation. Refactoring: Code quality improvements. Dependencies Management: Version updates and security patches. Application Development: Feature implementation and testing."
  }
];
